let data = [];
let currentLoggedIn = {};
currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
if(currentLoggedIn !== null){
    if(currentLoggedIn.role == 'admin')
    {
      data = [
        //Student Inquirey
        {
          path: '/student-inquiry',
          name: 'student-inquiry',
          component: () => import('@/views/apps/user/users-list/StudentInquiryList.vue'),
          meta: {
            pageTitle: 'Student Inquiry',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student Inquiry',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/student-inquiry/create-new-inquiry',
          name: 'create-new-inquiry',
          component: () => import('@/views/apps/user/users-list/createInquiry.vue'),
          meta: {
            pageTitle: 'Create Inquiry',
            breadcrumb: [
              {
                text: 'Student Inquiry',
                to:'/student-inquiry'
              },
              {
                text: 'Create Inquiry',
                active: true,
              }
            ],
          },
        },
        {
          path: '/student-inquiry/edit-inquiry/:name/:id',
          name: 'edit-inquiry',
          component: () => import('@/views/apps/user/users-list/editStudentInquiry.vue'),
          meta: {
            pageTitle: 'Edit Inquiry',
            breadcrumb: [
              {
                text: 'Student Inquiry',
                to:'/student-inquiry'
              },
              {
                text: 'Edit Inquiry',
                active: true,
              }
            ],
          },
        },
        {
          path: '/student-inquiry/student-inquiry-information/:name/:id',
          name: 'student-inquiry-information',
          component: () => import('@/views/apps/user/users-list/StudentInquiryInfo.vue'),
          meta: {
            pageTitle: 'Student Inquiry Information',
            breadcrumb: [
              {
                text: 'Student Inquiry',
                to:'/student-inquiry'
              },
              {
                text: 'Student Inquiry Information',
                active: true,
              }
            ],
          },
        },
        
        
        //Student management
        {
          path: '/student-mgmt',
          name: 'student-mgmt',
          component: () => import('@/views/admin/studentManagement/StudentList.vue'),
          meta: {
            pageTitle: 'Student List',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/student-mgmt/create-new-student',
          name: 'create-new-student',
          component: () => import('@/views/admin/studentManagement/createStudent.vue'),
          meta: {
            pageTitle: 'Create New Student',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Student List',
                to: '/student-mgmt'
              },
              {
                text: 'Create New Student',
                active: true,
              },
            ],
          },
        },
        {
          path: '/student-mgmt/student-information/:name/:id',
          name: 'student-information',
          component: () => import('@/views/admin/studentManagement/studentInformation.vue'),
          meta: {
            pageTitle: 'Student Information',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Student List',
                to: '/student-mgmt'
              },
              {
                text: 'Student Information',
                active: true,
              },
            ],
          },
        },
        {
          path: '/student-mgmt/edit-student',
          name: 'edit-student',
          component: () => import('@/views/admin/studentManagement/editStudent.vue'),
          meta: {
            pageTitle: 'Student Information',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Student List',
                to: '/student-mgmt'
              },
              {
                text: 'Student Information',
                active: true,
              },
            ],
          },
        },
      
        //Application Management
        {
          path: '/application-mgmt',
          name: 'application-mgmt',
          component: () => import('@/views/admin/applicationManagement/applicationList.vue'),
          meta: {
            pageTitle: 'Application Mgmt',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/application-mgmt/create-new-application',
          name: 'create-new-application',
          component: () => import('@/views/admin/applicationManagement/createNewApplication.vue'),
          meta: {
            pageTitle: 'Create New Application',
            breadcrumb: [
              {
                text: 'Application Mgmt',
                to: '/application-mgmt'
              },
              {
                text: 'Create New Application',
                active: true,
              },
            ],
          },
        },
        
        //Documents
        {
          path: '/doc-category-mgmt',
          name: 'doc-category-mgmt',
          component: () => import('@/views/admin/docCategoryManagement/categoryManagment.vue'),
          meta: {
            pageTitle: 'Document Category',
            breadcrumb: [        
              {
                text: 'Documents',
                to: '/doc-category-mgmt'
              },
              {
                text: 'Document Category',
                active:true        
              },
            ],
          },
        },
      
        //Documents
        {
          path: '/doc-category-mgmt/category-detaile',
          name: 'category-detaile',
          component: () => import('@/views/admin/docCategoryManagement/categoryDetail.vue'),
          meta: {
            pageTitle: 'Education',
            breadcrumb: [
              {
                text: 'Documents',          
              },
              {
                text: 'Document Category',
                to: '/doc-category-mgmt',
              },
              {
                text: 'Education',
                active: true,
              },
            ],
          },
        },
        // },
      
        //Create document module [JYOTI]
        {
          path: '/doc-category-mgmt/create-doc-category',
          name: 'create-doc-category',
          component: () => import('@/views/admin/docCategoryManagement/categoryModuleComponet.vue'),
          meta: {
            pageTitle: 'Create New Category',
            breadcrumb: [
              {
                text: 'Documents',
                to:'/doc-category-mgmt'
              },
              {
                text: 'Create New Category',
                active: true,
              },
            ],
          },
        },

        {
          path: '/doc-category-mgmt/Edit-doc-category',
          name: 'Edit-doc-category',
          component: () => import('@/views/admin/docCategoryManagement/editCategoryModule.vue'),
          meta: {
            pageTitle: 'Edit Category',
            breadcrumb: [
              {
                text: 'Documents',
                to:'/doc-category-mgmt'
              },
              {
                text: 'Edit Category',
                active: true,
              },
            ],
          },
        },
        
         //Edit doc module
        {
          path: '/doc-category-mgmt/edit-doc-category/:id',
          name: 'edit-doc-category',
          component: () => import('@/views/admin/docCategoryManagement/editCategory.vue'),
          meta: {
            pageTitle: 'Edit Doc Module',
            breadcrumb: [
              {
                text: 'Document List',
                to:'/doc-category-mgmt'
              },
              {
                text: 'Edit Doc Module',
                active: true,
              },
            ],
          },
        },
      
        //Company Document
        {
          path: '/company-document',
          name: 'company-document',
          component: () => import('@/views/ui/typography/companyDocument.vue'),
          meta: {
            pageTitle: 'Company Document',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/promotional',
          name: 'promotional-notification',
          component: () => import('@/views/admin/notificationManagment/PromotionalNotificationList.vue'),
          meta: {
            pageTitle: 'Promotional Notification',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/promotional-send',
          name: 'promotional-notification-send',
          component: () => import('@/views/admin/notificationManagment/promotinalNotification.vue'),
          meta: {
            pageTitle: 'Create Promotional Notification',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Promotional Notification',
                to:'/promotional'
              },
            ],
          },
        },
        {
          path: '/announcement',
          name: 'announcement',
          component: () => import('@/views/admin/announcementManagement/announcementList.vue'),
          meta: {
            pageTitle: 'Announcement',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/announcement-create',
          name: 'announcement-create',
          component: () => import('@/views/admin/announcementManagement/createAnnouncement.vue'),
          meta: {
            pageTitle: 'Create Announcement',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Announcement',
                to:'/announcement'
              },
            ],
          },
        },
        {
          path: '/community',
          name: 'community',
          component: () => import('@/views/admin/communityManagement/communityTab.vue'),
          meta: {
            pageTitle: 'Community',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        //Setting
        // {
        //   path: '/setting',
        //   name: 'setting',
        //   component: () => import('@/views/ui/typography/setting.vue'),
        //   meta: {
        //     pageTitle: 'Setting',
        //     breadcrumb: [
        //       // {
        //       //   text: 'UI',
        //       // },
        //       // {
        //       //   text: 'Student List',
        //       //   active: true,
        //       // },
        //     ],
        //   },
        // },


        // Cards
        //Employees
        {
          path: '/employee-mgmt',
          name: 'employee-mgmt',
          component: () => import('@/views/admin/adminUserManagement/employeeManagement/employeeList.vue'),
          meta: {
            pageTitle: 'Employees',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Admin User',
              },
              {
                text: 'Employees',
                active: true,
              },
            ],
          },
        },
        {
          path: '/employee-mgmt/create-new-employee',
          name: 'create-new-employee',
          component: () => import('@/views/admin/adminUserManagement/employeeManagement/createEmployee.vue'),
          meta: {
            pageTitle: 'Create New Employee',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Employees',
                to: '/employee-mgmt'
              },
              {
                text: 'Create New Employee',
                active: true,
              },
            ],
          },
        },
        {
          path: '/employee-mgmt/edit-employee',
          name: 'edit-employee',
          component: () => import('@/views/admin/adminUserManagement/employeeManagement/editEmployee.vue'),
          meta: {
            pageTitle: 'Employee Information',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Employees',
                to: '/employee-mgmt'
              },
              {
                text: 'Employee Information',
                active: true,
              },
            ],
          },
        },
        {
          path: '/employee-mgmt/employee-information',
          name: 'employee-information',
          component: () => import('@/views/admin/adminUserManagement/employeeManagement/employeeInformation.vue'),
          meta: {
            pageTitle: 'Employee Information',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              {
                text: 'Employees',
                to: '/employee-mgmt'
              },
              {
                text: 'Employee Information',
                active: true,
              },
            ],
          },
        },
        
        // Role Management
        {
          path: '/card/role-mgmt',
          name: 'role-mgmt',
          component: () => import('@/views/card/roleManagement/roleListing.vue'),
          meta: {
            pageTitle: 'Employee Roles',
            breadcrumb: [
              {
                text: 'Admin User',
              },
              {
                text: 'Employee Roles',    
                active: true,      
              },
            ],
          },    
        },
        // edit Role
        {
          path: '/card/role-detail-mgmt/:id/:name',
          name: 'role-detail-mgmt',
          component: () => import('@/views/card/roleManagement/editUserRole.vue'),
          meta: {
            pageTitle: 'Manager',
            breadcrumb: [
              {
                text: 'Admin User',
                to:'/card/role-mgmt'
              },
              {
                text: 'Manager',
                active: true,      
              },
            ],
          },    
        },

        // DOCUMENT MANAGMENT
        //Document List
        {
          path: '/doc-mgmt',
          name: 'doc-mgmt',
          component: () => import('@/views/ui/typography/documentManagment.vue'),
          meta: {
            pageTitle: 'Document List',
            breadcrumb: [        
              {
                text: 'Documents',
                to: '/doc-mgmt'
              },
              {
                text: 'Document List',
                active :true,
              },
            ],
          },
        },
        //***************Admin User management END***************



        //*******************Uni Management sub tabs
        //Field & Course Mgmt
        {
          path: '/uni-corse-mgmt/create-field-course', 
          name: 'create-field-course',
          component: () => import('@/views/admin/uni_courseManagement/createFieldCourse.vue'),
          meta: {
            pageTitle: 'Create Field & Course',
            breadcrumb: [
              {
                text: 'University/Courses',
                to: '/uni-course-mgmt/field-course-mgmt'
              },
              {
                text: 'Create Field & Course',
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/field-course-mgmt',
          name: 'field-course-mgmt',
          component: () => import('@/views/admin/uni_courseManagement/fieldCourseManagement.vue'),
          meta: {
            pageTitle: 'Field & Course Mgmt',
            breadcrumb: [
              {
                text: 'University/Courses',
              },
              {
                text: 'Field & Course Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/field-course-info',
          name: 'field-course-info',
          component: () => import('@/views/admin/uni_courseManagement/fieldCourseInformation.vue'),
          meta: {
            pageTitle: 'Field & Course Info',
            breadcrumb: [
              {
                text: 'University/Courses',
                to: '/uni-course-mgmt/field-course-mgmt'
              },
              {
                text: 'Field & Course Mgmt',
                to: '/uni-course-mgmt/field-course-mgmt'
              },
              {
                text: 'Field & Course Info',
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/edit-field-course',
          name: 'edit-field-course',
          component: () => import('@/views/admin/uni_courseManagement/editFieldCourse.vue'),
          meta: {
            pageTitle: 'Field & Course Edit',
            breadcrumb: [
              {
                text: 'University/Courses',
                to: '/uni-course-mgmt/field-course-mgmt'
              },
              {
                text: 'Field & Course Mgmt',
                to: '/uni-course-mgmt/field-course-mgmt'
              },
              {
                text: 'Field & Course Edit',
                active: true,
              },
            ],
          },
        },


        {
          path: '/uni-course-mgmt/university-mgmt', 
          name: 'university-mgmt',
          component: () => import('@/views/admin/uni_courseManagement/universityList.vue'),
          meta: {
            pageTitle: 'University Mgmt',
            breadcrumb: [
              {
                text: 'University/Courses',
              },
              {
                text: 'University Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/college-mgmt', 
          name: 'college-mgmt',
          component: () => import('@/views/admin/uni_courseManagement/collegeList.vue'),
          meta: {
            pageTitle: 'College Mgmt',
            breadcrumb: [
              {
                text: 'University/Courses',
              },
              {
                text: 'College Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/college-info', 
          name: 'college-info',
          component: () => import('@/views/admin/uni_courseManagement/collegeInformation.vue'),
          meta: {
            pageTitle: 'College Info',
            breadcrumb: [
              {
                text: 'University/Courses',
                to: '/uni-course-mgmt/college-mgmt'
              },
              {
                text: 'College Info',
                active: true,
              },            
            ],
          },
        },
        {
          path: '/application-Mgmt/aplication-mgmt-Info/:id/:name', 
          name: 'application-mgmt-Info',
          component: () => import('@/views/admin/applicationManagement/applicatonDetaile.vue'),
          meta: {
            pageTitle: 'Application Information',
            breadcrumb: [
              {
                text: 'Application Mgmt',
                to:"/application-mgmt"
              }, 
              {
                text: 'Application Information',          
                active: true,
              },      
            ],
          },
        },  
        {
          path: '/application-Mgmt/aplication-Mgmt-Edit/:id/:name', 
          name: 'application-mgmt-Edit',
          component: () => import('@/views/admin/applicationManagement/editApplication.vue'),
          meta: {
            pageTitle: 'Edit Application',
            breadcrumb: [
              {
                text: 'Application Mgmt',
                to:"/application-mgmt"
              }, 
              {
                text: 'edit-application-studentName',                
              }, 
              {
                text: 'Edit Application',          
                active: true,
              },      
            ],
          },
        },  
        // {
        //   path: '/uni-course-mgmt/university-mgmt', 
        //   name: 'university-mgmt',
        //   component: () => import('@/views/card/universityMgmt/universityList.vue'),
        //   meta: {
        //     pageTitle: 'University Mgmt',
        //     breadcrumb: [
        //       {
        //         text: 'University/Courses',
        //       },
        //       {
        //         text: 'University Mgmt',
        //         active: true,
        //       },
        //     ],
        //   },
        // },
      //Course Management sub tabs
        {
          path: '/uni-course-mgmt/course-mgmt',
          name: 'course-mgmt',
          component: () => import('@/views/card/courseMgmt/courseListing.vue'),
          meta: {
            pageTitle: 'Course Mgmt',
            breadcrumb: [
              {
                text: 'University/Courses',
              },
              {
                text: 'Course Mgmt',          
                active: true,
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/create-new-course',
          name: 'createNewCourse',
          component: () => import('@/views/card/courseMgmt/createCourse.vue'),
          meta: {
            pageTitle: 'Create Course',
            breadcrumb: [
              {
                text: 'University/Courses',
                active: true,
              },
              {
                text: 'Course Mgmt',          
              },
            ],
          },
        },
        {
          path: '/uni-course-mgmt/edit-new-course/:courceId',
          name: 'editCourse',
          component: () => import('@/views/card/courseMgmt/editCourse.vue'),
          meta: {
            pageTitle: 'Cyber Security',
            breadcrumb: [
              {
                text: 'University/Courses',
                active: true,
              },
              {
                text: 'Cyber Security',          
              },
            ],
          },
        },
        {
          path: '/information-document',
          name: 'information-document',
          component: () => import('@/views/admin/infoDocumentManagement/informationDocumentList.vue'),
          meta: {
            pageTitle: 'Information Document',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/information-crerate',
          name: 'information-crerate',
          component: () => import('@/views/admin/infoDocumentManagement/informationDecument.vue'),
          meta: {
            pageTitle: 'Create Information Document',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Information Document',
                to:'/information-document'
              },
            ],
          },
        },
        {
          path: '/information-update/:id',
          name: 'information-update',
          component: () => import('@/views/admin/infoDocumentManagement/informationUpdateDecument.vue'),
          meta: {
            pageTitle: 'Update Information Document',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Information Document',
                to:'/information-document'
              },
            ],
          },
        },
        {
          path: '/notice-mgnt',
          name: 'notice-mgnt',
          component: () => import('@/views/admin/noticeManagement/noticeLists.vue'),
          meta: {
            pageTitle: 'Notice Management',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        {
          path: '/notice-create',
          name: 'notice-create',
          component: () => import('@/views/admin/noticeManagement/createNotice.vue'),
          meta: {
            pageTitle: 'Create Notice',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Notice Management',
                to:'/notice-mgnt'
              },
            ],
          },
        },
        {
          path: '/notice-update/:id',
          name: 'notice-update',
          component: () => import('@/views/admin/noticeManagement/editNotice.vue'),
          meta: {
            pageTitle: 'Update Notice',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
              {
                text: 'Notice Management',
                to:'/notice-mgnt'
              },
            ],
          },
        },

        //Education details options management
        {
          path: '/edu-detail-mgmt/degree-list-mgmt', 
          name: 'degree-list-mgmt',
          component: () => import('@/views/admin/educationManagement/educationList.vue'),
          meta: {
            pageTitle: 'Degree List Mgmt',
            breadcrumb: [
              {
                text: 'Education Fields Management',
              },
              {
                text: 'Degree List Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/edu-detail-mgmt/university-list-mgmt', 
          name: 'university-list-mgmt',
          component: () => import('@/views/admin/educationManagement/universityList.vue'),
          meta: {
            pageTitle: 'University List Mgmt',
            breadcrumb: [
              {
                text: 'Education Fields Management',
              },
              {
                text: 'University List Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/edu-detail-mgmt/school-college-list-mgmt', 
          name: 'school-college-list-mgmt',
          component: () => import('@/views/admin/educationManagement/schoolCollegeList.vue'),
          meta: {
            pageTitle: 'School/College List Mgmt',
            breadcrumb: [
              {
                text: 'Education Fields Management',
              },
              {
                text: 'School/College List Mgmt',
                active: true,
              },
            ],
          },
        },
        {
          path: '/edu-detail-mgmt/branch-stream-field-mgmt', 
          name: 'branch-stream-field-mgmt',
          component: () => import('@/views/admin/educationManagement/filedStreamList.vue'),
          meta: {
            pageTitle: 'Branch/Stream/Field List Mgmt',
            breadcrumb: [
              {
                text: 'Education Fields Management',
              },
              {
                text: 'Branch/Stream/Field List Mgmt',
                active: true,
              },
            ],
          },
        },
        //Student management
        {
          path: '/visa-application/:id',
          name: 'visa-application',
          component: () => import('@/views/admin/studentManagement/editVisaApplications.vue'),
          meta: {
            pageTitle: 'Edit Visa Application',
            breadcrumb: [
              // {
              //   text: 'UI',
              // },
              // {
              //   text: 'Student List',
              //   active: true,
              // },
            ],
          },
        },
        //Applictions Notes
        {
          path: '/application-note-mgnt',
          name: 'application-note-mgnt',
          component: () => import('@/views/admin/applicationNoteManagement/noteLists.vue'),
          meta: {
            pageTitle: 'Application Note Management',
            breadcrumb: [],
          },
        },
        {
          path: '/application-note-create',
          name: 'application-note-create',
          component: () => import('@/views/admin/applicationNoteManagement/createNote.vue'),
          meta: {
            pageTitle: 'Create Application Note',
            breadcrumb: [
              {
                text: 'Application Note Management',
                to:'/application-note-mgnt'
              },
            ],
          },
        },
        {
          path: '/application-note-update/:id',
          name: 'application-note-update',
          component: () => import('@/views/admin/applicationNoteManagement/editNote.vue'),
          meta: {
            pageTitle: 'Update Application Note',
            breadcrumb: [
              {
                text: 'Application Note Management',
                to:'/application-note-mgnt'
              },
            ],
          },
        },
        // {
        //   path: '/bank-details-mgnt',
        //   name: 'bank-details-mgnt',
        //   component: () => import('@/views/admin/billingDetailsMangement/billingDetails.vue'),
        //   meta: {
        //     pageTitle: 'Bank Details Management',
        //     breadcrumb: [],
        //   },
        // },
        // {
        //   path: '/invoice-mgnt',
        //   name: 'invoice-mgnt',
        //   component: () => import('@/views/admin/invoiceManagement/pendingUserListMgnt.vue'),
        //   meta: {
        //     pageTitle: 'Invoice Management',
        //     breadcrumb: [],
        //   },
        // },
      ]
    }
    else
    {
      data = [];
    }
}
// else{
//   data = [
//     //Student Inquirey
//     {
//       path: '/login',
//       name: 'auth-login',
//       component: () => import('@/views/pages/authentication/Login.vue'),
//       meta: {
//         layout: 'full',
//         resource: 'Auth',
//         redirectIfLoggedIn: true,
//       },
//     },
//   ]
// }
export default data
