export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/student-profile/studentDetail',
    name: 'studentDetail',
    component: () => import('@/views/apps/student/studentList/studentDetail.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Profile',
      breadcrumb: [
        // {
        //   text: 'Layouts',
        // },
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-Doc/Documents',
    name: 'studentDocuments',
    component: () => import('@/views/apps/student/documents/documentManagement.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Application Documents',
      breadcrumb: [
        {
          text: 'Documents',          
        },
        {
          text: 'Application Documents',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-university/suggestedUniversity',
    name: 'studentSuggesteduniversity',
    component: () => import('@/views/apps/student/university/suggestedUniversity.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Suggested University',
      breadcrumb: [
        {
          text: 'University Selection',
        },
        {
          text: 'Suggested University',
          active: true,
        },
      ],
    },
  }, 
  {
    path: '/student-university/shortListUniversity',
    name: 'studentShortuniversity',
    component: () => import('@/views/apps/student/university/shortListedUniversity.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Shortlist University',
      breadcrumb: [
        {
          text: 'University Selection',
        },
        {
          text: 'Shortlist University',
          active: true,
        },
      ],
    },
  },

  //Visa Documents
  {
    path: '/visa-documents',
    name: 'studentVisaDocuments',
    component: () => import('@/views/apps/student/visaDocuments/visaDocumentsModule.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Visa Documents',
      breadcrumb: [
        {
          text: 'Visa Documents',
          active: true,
        },
      ]
    },
  }, 

  // APPLICATION MANAGEMENT
  {
    path: '/student-application/aplicationMgmt',
    name: 'studentapplicationMgmt',
    component: () => import('@/views/apps/student/applicationManagement/applicationMgmt.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Student Application Mgmt',    
    },
  }, 
  {
    path: '/student-application/aplicationShortLIst/:id/:name',
    name: 'studentapplicationDetails',
    component: () => import('@/views/apps/student/applicationManagement/applicationDetail.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Student Application Detail',  
      breadcrumb: [
        {
          text: 'Application Mgmt',
          to:'/student-application/aplicationMgmt'
        },
        {
          text: 'Application Information',
          active: true,
        },
      ],  
    },
  },
  {
    path: '/student-application/Edit-Details/:id/:name',
    name: 'studentapplicationEditDetails',
    component: () => import('@/views/apps/student/studentList/editStudentDetailes.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Student Edit-Application',  
      breadcrumb: [
        {
          text: 'Application Mgmt',
          to:'/student-application/aplicationMgmt'
        },
        {
          text: 'Student Edit-Application',
          active: true,
        },
      ],  
    },
  },
  {
    path: '/sop-documents-upload',
    name: 'studentsopupload',
    component: () => import('@/views/apps/student/sopUploadStudent/studentSopUpload.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'SOP',
      breadcrumb: [
        {
          text: 'SOP',
          active: true,
        },
      ]
    },
  },
  {
    path: '/information-documents-list',
    name: 'studentinformationDocList',
    component: () => import('@/views/apps/student/informationDocument/informationDocList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Information Document',
      breadcrumb: [
        {
          text: 'Information Document',
          active: true,
        },
      ]
    },
  },
  {
    path: '/student-visa-application',
    name: 'studentVisaApplication',
    component: () => import('@/views/admin/studentManagement/editVisaApplications.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Visa Application',
      breadcrumb: [
        {
          text: 'Visa Application',
          active: true,
        },
      ]
    },
  },
  //Student management
  {
    path: '/visa-application/:id',
    name: 'visa-application',
    component: () => import('@/views/admin/studentManagement/editVisaApplications.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Edit Visa Application',
      breadcrumb: [
        {
          text: 'Edit Visa Application',
          active: true,
        },
      ]
    },
  },
]
