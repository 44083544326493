export default{
  //ERFOLG2 Local
  // apiKey: "AIzaSyDU5j1t8CmEimq_D4QwV8tTuy3io3Jo-rM",
  // authDomain: "erfolg2-24280.firebaseapp.com",
  // projectId: "erfolg2-24280",
  // storageBucket: "erfolg2-24280.appspot.com",
  // messagingSenderId: "28990493313",
  // appId: "1:28990493313:web:02ae3c09d84fe083f4b380",
  // measurementId: "${config.measurementId}"

  //ERFOLG COMPANY
  // apiKey: "AIzaSyB90gE0gCfUl9Ogscg2ETz1U2WTZKoIIT0",
  // authDomain: "erfolg-16de9.firebaseapp.com",
  // projectId: "erfolg-16de9",
  // storageBucket: "erfolg-16de9.appspot.com",
  // messagingSenderId: "1036606796763",
  // appId: "1:1036606796763:web:b9f8bd35b64b1f1e136dfe",
  // measurementId: "G-99BPGKVV74"
  // apiKey: "AIzaSyDOjKgxurstPkceOaXOB6SefzYkUkShHUQ",
  // authDomain: "chat-erf.firebaseapp.com",
  // projectId: "chat-erf",
  // storageBucket: "chat-erf.appspot.com",
  // messagingSenderId: "521297068222",
  // appId: "1:521297068222:web:34925718fceb24ec855eef"
  // apiKey: "AIzaSyCAGos37oK-rtFa4C3b_VdT2mqjTn1orio",
  // authDomain: "test-erf.firebaseapp.com",
  // projectId: "test-erf",
  // storageBucket: "test-erf.appspot.com",
  // messagingSenderId: "646763548051",
  // appId: "1:646763548051:web:27a087da21bb8cbdd52c66"


  // helee
  // apiKey: "AIzaSyBcrbb27YwF7BVARhhpdeGoQ8a3gsxNKgI",
  // authDomain: "mytest-d39da.firebaseapp.com",
  // projectId: "mytest-d39da",
  // storageBucket: "mytest-d39da.appspot.com",
  // messagingSenderId: "319278395973",
  // appId: "1:319278395973:web:894850ab34dd4b1890292c"

  //jeel
  // apiKey: "AIzaSyCAGos37oK-rtFa4C3b_VdT2mqjTn1orio",
  // authDomain: "test-erf.firebaseapp.com",
  // projectId: "test-erf",
  // storageBucket: "test-erf.appspot.com",
  // messagingSenderId: "646763548051",
  // appId: "1:646763548051:web:27a087da21bb8cbdd52c66"

  // Beta Live
  // apiKey: "AIzaSyAaJoA7iNdfiwk8W3zbZSO-j3TXivibxwo",
  // authDomain: "erflogbeta.firebaseapp.com",
  // projectId: "erflogbeta",
  // storageBucket: "erflogbeta.appspot.com",
  // messagingSenderId: "92751113542",
  // appId: "1:92751113542:web:1f3543a98675e2e78d4947",
  // measurementId: "G-RZCFPS4WM1"
  

  //ERFOLG LIVE
  apiKey: "AIzaSyCY5-PqIWzPw3KN4VuSMVYGDDQn7XDA8NE",
  authDomain: "erfolg-system-live.firebaseapp.com",
  projectId: "erfolg-system-live",
  storageBucket: "erfolg-system-live.appspot.com",
  messagingSenderId: "787003647613",
  appId: "1:787003647613:web:8f7eab4965a11dfd227314"
}