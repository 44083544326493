import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/ui'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import employeeRoutes from './routes/employeeRoutes'
import { getRoutesData } from './routes/employeeRoutes'
Vue.use(VueRouter)

var currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
// if(Object.keys(currentLoggedIn).length > 0){

// var employeeRoutes = []
// getRoutesData(res=>{
//   employeeRoutes = res
// })
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    currentLoggedIn!=null?{ path: '/', redirect: currentLoggedIn.role == 'admin'?{name: 'student-inquiry'}:currentLoggedIn.role == 'employee'?{name: 'employee-profile'}:{name: 'studentDetail'}}:{ path: '/', redirect:{name:'auth-login'}},
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...employeeRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ]
})
// }else{
//   console.log("log OUt")
// }
// if(currentRole !== undefined){
//   console.log(currentRole.role ,"currentRole.role ")
//   if(currentRole.role !== 'admin'){
//     router = new VueRouter({
//       mode: 'history',
//       base: process.env.BASE_URL,
//       scrollBehavior() {
//         return { x: 0, y: 0 }
//       },
//       routes: [
//         // { path: '/', redirect: { name: 'student-inquiry' } },
//         // ...apps,
//         // ...dashboard,
//         // ...pages,
//         // ...chartsMaps,
//         // ...formsTable,
//         // ...uiElements,
//         ...others,
//         {
//           path: '*',
//           redirect: 'error-404',
//         },
//       ],
//     })
//     console.log(router)
//   }
// }

// console.log("Apps",...apps,
// "dashboard",...dashboard,
// "pages",...pages,
// "chartsMaps",...chartsMaps,
// "formsTable",...formsTable,
// "uiElements",...uiElements,
// "others",...others,
// "employeeRoutes",...employeeRoutes)

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    console.log("Next")
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router;