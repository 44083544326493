import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()

/******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION *******************/
export const getDataFromRootCollection = (collectionName, cb) => {
    db.collection(collectionName).onSnapshot((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
}

/******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION *******************/
export const getDataWithSpecificWhereCondition = ( collectionName, fieldName, condition, fieldValue, cb) => {
    db.collection(collectionName).where(fieldName, condition, fieldValue).onSnapshot((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
  }

  /******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION WITH WHERE CONDITION AND ORDER BY CREATEDAT *******************/
export const getDataWithSpecificWhereConditionAndOrderBy = ( collectionName, cb) => {
    db.collection(collectionName).orderBy('createdAt','desc').onSnapshot((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
  }

/******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION *******************/
export const getDataFromRootCollectionWithDocID = (collectionName,id, cb) => {
    db.collection(collectionName).doc(id).onSnapshot((doc) => {
        let response = {};
        if (doc.exists) {          
            response = doc.data();
            cb(response);
        } else {           
            cb(response);
        }
    });
}

/******* QUERY FOR GET DATA FROM FIRESTORE ROOT COLLECTION *******************/
export const getDataFromRootCollectionWithDocIDV2 = (collectionName,id, cb) => {
    db.collection(collectionName).doc(id).get().then((doc) => {
        let response = {};
        if (doc.exists) {          
            response = doc.data();
            cb(response);
        } else {           
            cb(response);
        }
    }).catch((error) => {
        console.log(error,'error')
    });
}

export const getDataFromRootCollectionv2 = (collectionName, cb) => {
    db.collection(collectionName).get().then((snapshot) => {
        let response = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                response.push({id: doc.id, ...doc.data()})
            });
            cb(response);
        } else {
            cb(response);
        }
    });
}
/******* QUERY FOR GET DATA FROM FIRESTORE SUB COLLECTION *******************/
// export const getDataFromSubCollectionWithDocID = (collectionName,id, cb) => {
//     db.collection(collectionName).doc(id).onSnapshot((doc) => {
//         let response = {};
//         if (doc.exists) {
//             response = doc.data();
//             cb(response);
//         } else {
//             cb(response);
//         }
//     });
// }