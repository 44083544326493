import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
  namespaced: true,
  state: {
    users:[]
  },
  getters: {
    getUsers: state => state.users
  },
  mutations: {
    setUsersArr(state,payload){
      state.users = payload;
    },
    updateArrayValue(state,payload){
        let index = state.users.findIndex((ele)=>{
            return ele.id == payload.data.id
        })
        if(index !== -1) {
            state.users[index] = payload.data
        }
    }
  },
  actions: {
    getUsersData(state){
      return new Promise((resolve,reject)=>{
        try{
          let users = [];
          db
            .collection(dbCollections.USERS)
            .orderBy('createdAt','desc')
            .get().then(snapshot=>{
              users = [];
              if(snapshot.empty){
                resolve("No Data Found.");
              }
              else
              {
                snapshot.forEach((doc)=>{
                    users.push(doc.data());
                });
                state.commit("setUsersArr",users);

                if(users.length == 0)
                {
                  resolve("No Data Found.");
                }
                else
                {
                    resolve("Records Found.");
                };
              }
            })
            .catch((error)=>{
              reject(error);
            });
        }
        catch(error){
          console.log("Error",error);
          reject(error);
        };
      });
    }
  },
}
