/**
 * 
 * This file is contains all of the firestore collection name which is used in our 
 * project. If your have required to create any new collection then you must be decalred here
 * and used as globally.
 * 
 */

/** ROOT COLLECTION NAME **/
const dbCollections = {
    // APIURL:'http://localhost:4000/',//Local
    // APIURL:'https://api-erfolg.aliansoftware.net/',//COMPANY
    APIURL:'https://api.erfolgcounseling.com/',//LIVE COUNSELING
    USERS : 'users',
    INQUIRY:'inquiry',
    SETTING:'settings',  
    DOCUMENTS:'documents',
    DOCUMENTCATEGORY:'documentCategory',
    DOCUMENTMODULE:'documentModule',
    COMPANYDOCUMENTS:'companyDocuments',    
    FIELDS:'fields',
    FIELDANDCOURSE:'fieldAndCourse',
    UNIVERSITIES:'universities',
    COLLEGES:'colleges',
    COURSES:'courses',
    USERROLES:'userRoles' ,
    APPLICATIONS:'applications',
    VISADOCUMENTS:"visaDocuments",
    POMOTIONALNOTIFICATION: "promtionalNotification",
    ANNOUNCEMENT: "announcement",
    COMMUNITY:'communityMessages',
    INFORMATIONDOCUMENT:'informationDocuments',
    BRANCHSTREAMFIELDS:'branchStreamFields',
    COLLEGESFIEDLS:'collegesFields',
    UNIVERSITIESFIELDS:'universitiesFields',
    DEGREEFIELD:'degreesFields',
    FIELDVALUE:'fieldValue',
    NOTICES:'notices',
    VISAAPPLICATION:'visaApplications',
    HISTORY:'history',
    INVOICES:'invoices',
    SUBSCRIBEDUSERS:'subscribedUsers',
    NOTIFICATIONS:'notifications',
    APPLICATIONNOTES:'applicationnotes',
    COURSES:'courses'
}
const settingsCollectionDocs = {
    DOCUMENTCATEGORY: 'documentCategory'
}
module.exports = {
    dbCollections,
    settingsCollectionDocs
};