import firebase from "@/utils/firebaseInit";
export const fcmToken = () => {
    return new Promise((resolve, reject) => {
        try {
            const messaging = firebase.messaging();
            Notification.requestPermission().then(async(requestPermission) => {
                try {
                    if (requestPermission === "granted") {
                        let currentToken = await messaging.getToken({ vapidKey: process.env.VUE_APP_VAPIDKEY});
                        if (currentToken) {
                            resolve({status:true,token:currentToken,message:'granted'});
                        }
                    } else if(requestPermission === "denied") {
                        resolve({status:true,token:'',message:'denied'})
                    } else {
                        resolve({status:true,token:'',message:'denied'})
                    }
                } catch (e) {
                    reject({status:true,token:'',message:e.message});
                }
            }).catch((e) => {
                reject({status:true,token:'',message:e.message})
            })
        } catch (error) {
            reject({status:true,token:'',message:error.message});
        }
    })
}