<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import firebase from '@/utils/firebaseInit'

import store from '@/store'

import moment from 'moment'
import { fcmToken } from '@/utils/fcmTockenGenerator.js'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from './utils/firebaseCollection'
import firebaseGlobal from 'firebase/app';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
import firebaseTimeStamp from "firebase";
const db = firebase.firestore()

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,

    moment
  },
  data()
  {
    return{
      idleTime:0,
    }
  },
  created()
  {
    this.$root.$on('showToastMessage',(title,variant)=>{
      this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: title,
              variant,
              icon: 'InfoIcon'
            },
        })
    })
    firebase.auth().onAuthStateChanged(user=>{
      if(user)
      {
        if(user.uid) {
          db.collection(dbCollections.USERS).doc(user.uid).get().then((userRes)=>{
            if(userRes.exists) {
              if(userRes.data().isDeleted == true || userRes.data().isActive == false) {
                this.logoutUser();
              }
            } else {
              this.logoutUser();
            }
          })
        }
      } else {
        this.logoutUser();
      }
    })
  },
  methods: {
    logoutUser()
    {
      this.$root.$emit('logoutUser')
    },
    async generateFcmToken(type=false) {
      try{
        if(type == false) {
          getQuery.getDataFromRootCollectionWithDocIDV2(dbCollections.USERS, firebase.auth().currentUser.uid,async(res)=>{
            if(Object.keys(res).length == 0) {
              return
            } else {
              const result = await fcmToken();
              if(result.status && result.token !== '') {
                if(localStorage.getItem('webtoken') == null || (localStorage.getItem('webtoken') && localStorage.getItem('webtoken') !== result.token)) {
                  let obj = {
                    'fcmToken': res.fcmToken ? firebaseGlobal.firestore.FieldValue.arrayUnion(result.token) : [result.token],
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                  }
                  await updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.USERS,firebase.auth().currentUser.uid,obj,() => {
                    getQuery.getDataFromRootCollectionWithDocIDV2(dbCollections.SUBSCRIBEDUSERS,'student',async(res2)=>{  
                      let obj2 = {
                        'fcmToken': res2.fcmToken ? firebaseGlobal.firestore.FieldValue.arrayUnion(result.token) : [result.token]
                      }
                      updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.SUBSCRIBEDUSERS,'student',obj2,()=>{
                        localStorage.setItem('webtoken',result.token);
                      })
                    })
                  })
                }
              }
            }
          });
        } else {
          let token = localStorage.getItem('webtoken');
          if(token) {
            let obj = {
              fcmToken: token ? firebaseGlobal.firestore.FieldValue.arrayRemove(token) : [],
              'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }
            updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.USERS,firebase.auth().currentUser.uid,obj,() => {
              let obj1 = {
                fcmToken: token ? firebaseGlobal.firestore.FieldValue.arrayRemove(token) : []
              }
              updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.SUBSCRIBEDUSERS,'student',obj1,(res)=>{
                localStorage.removeItem('webtoken');
              })
            })
          }
        }
      }catch(e) {
        console.error(e);
      }
    },
  },
  mounted()
  {
    //Increment the idle time counter every minute.
    // eslint-disable-next-line

    var self = this

    firebase.auth().onAuthStateChanged(async(user)=>{
      if(user)
      {
        var idleInterval;
        // console.log("User Found!!")
        function timerIncrement() {
          self.idleTime = self.idleTime + 1;
          if (self.idleTime >= 60) { // 45 minutes
            // console.log("Logout")
            self.logoutUser()

            clearInterval(idleInterval);

            resetTimer;
            // let vendorId = '';
            // if (localStorage.getItem('selectedVendorData')) {
            //   vendorId = JSON.parse(localStorage.getItem('selectedVendorData')).id;
            // } else {
            //   vendorId = JSON.parse(localStorage.getItem('selectedVendorDataRece')).id;
            // }
            // localStorage.removeItem('addToCard');
            // if (localStorage.getItem('orderReceiveData')) {
            //   localStorage.removeItem('orderReceiveData');
            // }
            // localStorage.removeItem('delivery');
            // window.location.href = `${window.location.origin}/vendorproduct/${vendorId}`
          }
        }
    
        function resetTimer() {
          self.idleTime = 0;
    
          // console.log("Reset")
        }
        // $(self).keypress(function (e) {
        //     self.idleTime = 0;
        // });
        // $(self).keyup(function (e) {
        //   self.idleTime = 0;
        // });
    
        idleInterval = setInterval(timerIncrement, 60000); // 1 minute
    
        // $(self).mousemove(function (e) {
        //     self.idleTime = 0;
        // });
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;
        window.onclick = resetTimer;
        window.onscroll = resetTimer;
        window.onkeypress = resetTimer;
        if ('Notification' in window) {
          if (Notification.permission === 'granted') {
            self.generateFcmToken();
          } else if (Notification.permission === 'denied') {
            self.generateFcmToken(true);
          } else {
            Notification.requestPermission()
              .then(permission => {
                if (permission === 'granted') {
                  self.generateFcmToken();
                } else {
                  self.generateFcmToken(true);
                }
              })
              .catch(error => {
                console.error('Error occurred while requesting notification permission:', error);
              });
          }
        } else {
          self.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
          self.generateFcmToken();
        }
      }
    })

    // firebase.auth().onAuthStateChanged(user=>{
    //     let userSessionTimeout = null;

    //     if (user === null) {
    //       clearTimeout(userSessionTimeout);
    //       userSessionTimeout = null;
    //     } 
    //     else if(user!=null){
    //       console.log("User",user)
    //       var tt = moment(user.metadata.lastSignInTime).valueOf()
    //       console.log("Login Time",new Date(tt));
    //       console.log("idTokenResult",Date.now() ,tt)
    //       // firebase.User.getIdTokenResult().then((idTokenResult) => {
    //         const authTime = tt;
    //         // const sessionDurationInMilliseconds = 60 * 60 * 1000; // 60 min
    //         const sessionDurationInMilliseconds = 30 * 1000; // 60 min
    //         let value = (authTime+sessionDurationInMilliseconds)  - (new Date().getTime());

    //         if (value < 0) {
    //             value =  30 * 1000
    //         }
    //         console.log()
    //         const expirationInMilliseconds = value
    //         // if(expirationInMilliseconds<0)
    //         // {
    //         //   expirationInMilliseconds = sessionDurationInMilliseconds
    //         // }

    //         console.log("Exp",sessionDurationInMilliseconds,expirationInMilliseconds)
    //         userSessionTimeout = setTimeout(() => {
    //           console.log("Logout");
    //           // this.$root.$emit('logoutUser')
    //           userSessionTimeout=null;
    //         }, expirationInMilliseconds);
    //       // });
    //     }
    // })
  
    // firebase.auth().onAuthStateChanged((user) => {
    //   let userSessionTimeout = null;

    //   if (user === null && userSessionTimeout) {
    //     clearTimeout(userSessionTimeout);
    //     userSessionTimeout = null;
    //   } else {
    //     user.getIdTokenResult().then((idTokenResult) => {
    //       const authTime = idTokenResult.claims.auth_time * 1000;
    //       const sessionDurationInMilliseconds = 1 * 30 * 1000; // 60 min
    //       const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
    //       userSessionTimeout = setTimeout(() => firebase.auth().signOut(), expirationInMilliseconds);
    //     });
    //   }
    // });
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
