import firebase from '@/utils/firebaseInit'
const db = firebase.firestore()
var routes = []

let data = [];
let currentLoggedIn = {};
currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
// if(currentLoggedIn !== null){
//     if(currentLoggedIn.role == 'employee')
//     {
//       data = [
//         {
//           path: '/access-control',
//           name: 'access-control',
//           component: () => import('@/views/extensions/acl/AccessControl.vue'),
//           meta: {
//             resource: 'EAC',
//             action: routeData.allow.toLowerCase(),
//           },
//         },
//         //My Profile
//         {
//           path: '/employee-profile',
//           name: 'employee-profile',
//           component: () => import('@/views/employee/employeeInformation.vue'),
//           meta: {
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//             pageTitle: 'Profile',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               {
//                 text: 'Profile',
//                 active: true,
//               },
//             ],
//           },
//         },
//         {
//           path: '/edit-employee-profile',
//           name: 'edit-employee-profile',
//           component: () => import('@/views/employee/editEmployee.vue'),
//           meta: {
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//             pageTitle: 'Profile',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               {
//                 text: 'Profile',
//                 active: true,
//               },
//             ],
//           },
//         },

//         //Student Inquirey
//         {
//           path: '/student-inquiry',
//           name: 'student-inquiry',
//           component: () => import('@/views/apps/user/users-list/StudentInquiryList.vue'),
//           meta: {
//             pageTitle: 'Student Inquiry',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               // {
//               //   text: 'Student Inquiry',
//               //   active: true,
//               // },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         {
//           path: '/student-inquiry/create-new-inquiry',
//           name: 'create-new-inquiry',
//           component: () => import('@/views/apps/user/users-list/createInquiry.vue'),
//           meta: {
//             pageTitle: 'Create Inquiry',
//             breadcrumb: [
//               {
//                 text: 'Student Inquiry',
//                 to:'/student-inquiry'
//               },
//               {
//                 text: 'Create Inquiry',
//                 active: true,
//               }
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
        
//         //Student management
//         {
//           path: '/student-mgmt',
//           name: 'student-mgmt',
//           component: () => import('@/views/admin/studentManagement/StudentList.vue'),
//           meta: {
//             pageTitle: 'Student List',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               // {
//               //   text: 'Student List',
//               //   active: true,
//               // },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         {
//           path: '/student-mgmt/create-new-student',
//           name: 'create-new-student',
//           component: () => import('@/views/admin/studentManagement/createStudent.vue'),
//           meta: {
//             pageTitle: 'Create New Student',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               {
//                 text: 'Student List',
//                 to: '/student-mgmt'
//               },
//               {
//                 text: 'Create New Student',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         {
//           path: '/student-mgmt/student-information',
//           name: 'student-information',
//           component: () => import('@/views/admin/studentManagement/studentInformation.vue'),
//           meta: {
//             pageTitle: 'Student Information',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               {
//                 text: 'Student List',
//                 to: '/student-mgmt'
//               },
//               {
//                 text: 'Student Information',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         {
//           path: '/student-mgmt/edit-student',
//           name: 'edit-student',
//           component: () => import('@/views/admin/studentManagement/editStudent.vue'),
//           meta: {
//             pageTitle: 'Student Information',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               {
//                 text: 'Student List',
//                 to: '/student-mgmt'
//               },
//               {
//                 text: 'Student Information',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
      
//         //Application Management
//         {
//           path: '/application-mgmt',
//           name: 'application-mgmt',
//           component: () => import('@/views/admin/applicationManagement/applicationList.vue'),
//           meta: {
//             pageTitle: 'Application Mgmt',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               // {
//               //   text: 'Student List',
//               //   active: true,
//               // },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         {
//           path: '/application-mgmt/create-new-application',
//           name: 'create-new-application',
//           component: () => import('@/views/admin/applicationManagement/createNewApplication.vue'),
//           meta: {
//             pageTitle: 'Create New Application',
//             breadcrumb: [
//               {
//                 text: 'Application Mgmt',
//                 to: '/application-mgmt'
//               },
//               {
//                 text: 'Create New Application',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
        
//         //Documents
//         {
//           path: '/doc-category-mgmt',
//           name: 'doc-category-mgmt',
//           component: () => import('@/views/admin/docCategoryManagement/categoryManagment.vue'),
//           meta: {
//             pageTitle: 'Document Category',
//             breadcrumb: [        
//               {
//                 text: 'Documents',
//                 to: '/doc-category-mgmt'
//               },
//               {
//                 text: 'Document Category',
//                 active:true        
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
      
//         //Documents
//         {
//           path: '/doc-category-mgmt/category-detaile',
//           name: 'category-detaile',
//           component: () => import('@/views/admin/docCategoryManagement/categoryDetail.vue'),
//           meta: {
//             pageTitle: 'Education',
//             breadcrumb: [
//               {
//                 text: 'Documents',          
//               },
//               {
//                 text: 'Document Category',
//                 to: '/doc-category-mgmt',
//               },
//               {
//                 text: 'Education',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//         // },
      
//         //Create document module [JYOTI]
//         {
//           path: '/doc-category-mgmt/create-doc-category',
//           name: 'create-doc-category',
//           component: () => import('@/views/admin/docCategoryManagement/categoryModuleComponet.vue'),
//           meta: {
//             pageTitle: 'Create New Category',
//             breadcrumb: [
//               {
//                 text: 'Documents',
//                 to:'/doc-category-mgmt'
//               },
//               {
//                 text: 'Create New Category',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
        
//          //Edit doc module
//         {
//           path: '/doc-category-mgmt/edit-doc-category/:id',
//           name: 'edit-doc-category',
//           component: () => import('@/views/admin/docCategoryManagement/editCategory.vue'),
//           meta: {
//             pageTitle: 'Edit Doc Module',
//             breadcrumb: [
//               {
//                 text: 'Document List',
//                 to:'/doc-category-mgmt'
//               },
//               {
//                 text: 'Edit Doc Module',
//                 active: true,
//               },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
      
//         //Company Document
//         {
//           path: '/company-document',
//           name: 'company-document',
//           component: () => import('@/views/ui/typography/companyDocument.vue'),
//           meta: {
//             pageTitle: 'Company Document',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               // {
//               //   text: 'Student List',
//               //   active: true,
//               // },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
      
//         //Setting
//         {
//           path: '/setting',
//           name: 'setting',
//           component: () => import('@/views/ui/typography/setting.vue'),
//           meta: {
//             pageTitle: 'Setting',
//             breadcrumb: [
//               // {
//               //   text: 'UI',
//               // },
//               // {
//               //   text: 'Student List',
//               //   active: true,
//               // },
//             ],
//             action: routeData.allow.toLowerCase(),
//             resource: 'EAC',
//           },
//         },
//       ]
//     }
//     else
//     {
//       data = [];
//     }
// }

export const getRoutesData = () => {
  if(currentLoggedIn !== null){
    if(currentLoggedIn.role == 'employee')
    {
      
      var access = JSON.parse(localStorage.getItem('userAccess'))
      if(access!== null)
      {
        routes.push(
          {
            path: '/access-control',
            name: 'access-control',
            component: () => import('@/views/extensions/acl/AccessControl.vue'),
            meta: {
              resource: 'EAC',
              action: 'write',
            },
          },
          //My Profile
          {
            path: '/employee-profile',
            name: 'employee-profile',
            component: () => import('@/views/employee/profile/employeeInformation.vue'),
            meta: {
              action: 'write',
              resource: 'EAC',
              pageTitle: 'Profile',
              breadcrumb: [
                // {
                //   text: 'UI',
                // },
                {
                  text: 'Profile',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/edit-employee-profile',
            name: 'edit-employee-profile',
            component: () => import('@/views/employee/profile/editEmployee.vue'),
            meta: {
              action: 'write',
              resource: 'EAC',
              pageTitle: 'Profile',
              breadcrumb: [
                // {
                //   text: 'UI',
                // },
                {
                  text: 'Profile',
                  active: true,
                },
              ],
            },
          }
        )
        access.forEach(routeData=>{
          if(routeData.name == "Student Inquiry")
          {
            routes.push(
              //Student Inquirey
              {
                path: '/student-inquiry',
                name: 'student-inquiry',
                component: () => import('@/views/apps/user/users-list/StudentInquiryList.vue'),
                meta: {
                  pageTitle: 'Student Inquiry',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student Inquiry',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-inquiry/create-new-inquiry',
                name: 'create-new-inquiry',
                component: () => import('@/views/apps/user/users-list/createInquiry.vue'),
                meta: {
                  pageTitle: 'Create Inquiry',
                  breadcrumb: [
                    {
                      text: 'Student Inquiry',
                      to:'/student-inquiry'
                    },
                    {
                      text: 'Create Inquiry',
                      active: true,
                    }
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-inquiry/edit-inquiry/:name/:id',
                name: 'edit-inquiry',
                component: () => import('@/views/apps/user/users-list/editStudentInquiry.vue'),
                meta: {
                  pageTitle: 'Edit Inquiry',
                  breadcrumb: [
                    {
                      text: 'Student Inquiry',
                      to:'/student-inquiry'
                    },
                    {
                      text: 'Edit Inquiry',
                      active: true,
                    }
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-inquiry/student-inquiry-information/:name/:id',
                name: 'student-inquiry-information',
                component: () => import('@/views/apps/user/users-list/StudentInquiryInfo.vue'),
                meta: {
                  pageTitle: 'Student Inquiry Information',
                  breadcrumb: [
                    {
                      text: 'Student Inquiry',
                      to:'/student-inquiry'
                    },
                    {
                      text: 'Student Inquiry Information',
                      active: true,
                    }
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          }
          else if(routeData.name=='Student List' || routeData.name=='Student List')
          {
            routes.push(
              //Student management
              {
                path: '/student-mgmt',
                name: 'student-mgmt',
                component: () => import('@/views/employee/studentManagement/StudentList.vue'),
                meta: {
                  pageTitle: 'Student List',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-mgmt/create-new-student',
                name: 'create-new-student',
                component: () => import('@/views/employee/studentManagement/createStudent.vue'),
                meta: {
                  pageTitle: 'Create New Student',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Student List',
                      to: '/student-mgmt'
                    },
                    {
                      text: 'Create New Student',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-mgmt/student-information/:name/:id',
                name: 'student-information',
                component: () => import('@/views/employee/studentManagement/studentInformation.vue'),
                meta: {
                  pageTitle: 'Student Information',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Student List',
                      to: '/student-mgmt'
                    },
                    {
                      text: 'Student Information',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/student-mgmt/edit-student',
                name: 'edit-student',
                component: () => import('@/views/employee/studentManagement/editStudent.vue'),
                meta: {
                  pageTitle: 'Student Information',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Student List',
                      to: '/student-mgmt'
                    },
                    {
                      text: 'Student Information',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },

              //Application Management
              {
                path: '/application-Mgmt/aplication-mgmt-Info/:id/:name', 
                name: 'application-mgmt-Info',
                component: () => import('@/views/admin/applicationManagement/applicatonDetaile.vue'),
                meta: {
                  pageTitle: 'Application Information',
                  breadcrumb: [
                    {
                      text: 'Application Mgmt',
                      to:'/application-mgmt'
                    }, 
                    {
                      text: 'Application Information',          
                      active: true,
                    },      
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              }
            )
            if(routeData.allow.toLowerCase()=='write')
            {
              routes.push({
                path: '/application-mgmt/create-new-application',
                name: 'create-new-application',
                component: () => import('@/views/admin/applicationManagement/createNewApplication.vue'),
                meta: {
                  pageTitle: 'Create New Application',
                  breadcrumb: [
                    {
                      text: 'Application Mgmt',
                      to: '/application-mgmt'
                    },
                    {
                      text: 'Create New Application',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/visa-application-edit/:id',
                name: 'visa-application-edit',
                component: () => import('@/views/admin/studentManagement/editVisaApplications.vue'),
                meta: {
                  pageTitle: 'Edit Visa Application',
                  breadcrumb: [
                    {
                      text: 'Edit Visa Application',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              )
            }
          }
          else if(routeData.name=='Application Mgmt' || routeData.name=='Application Management')
          {
            routes.push(
              //Application Management
              {
                path: '/application-mgmt',
                name: 'application-mgmt',
                component: () => import('@/views/admin/applicationManagement/applicationList.vue'),
                meta: {
                  pageTitle: 'Application Mgmt',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/application-mgmt/create-new-application',
                name: 'create-new-application',
                component: () => import('@/views/admin/applicationManagement/createNewApplication.vue'),
                meta: {
                  pageTitle: 'Create New Application',
                  breadcrumb: [
                    {
                      text: 'Application Mgmt',
                      to: '/application-mgmt'
                    },
                    {
                      text: 'Create New Application',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              }
            )
          }
          else if(routeData.name=='Admin User')
          {
            routes.push(
              //Employees
              {
                path: '/employee-mgmt',
                name: 'employee-mgmt',
                component: () => import('@/views/admin/adminUserManagement/employeeManagement/employeeList.vue'),
                meta: {
                  pageTitle: 'Employees',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Admin User',
                    },
                    {
                      text: 'Employees',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/employee-mgmt/create-new-employee',
                name: 'create-new-employee',
                component: () => import('@/views/admin/adminUserManagement/employeeManagement/createEmployee.vue'),
                meta: {
                  pageTitle: 'Create New Employee',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Employees',
                      to: '/employee-mgmt'
                    },
                    {
                      text: 'Create New Employee',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/employee-mgmt/edit-employee',
                name: 'edit-employee',
                component: () => import('@/views/admin/adminUserManagement/employeeManagement/editEmployee.vue'),
                meta: {
                  pageTitle: 'Employee Information',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Employees',
                      to: '/employee-mgmt'
                    },
                    {
                      text: 'Employee Information',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/employee-mgmt/employee-information',
                name: 'employee-information',
                component: () => import('@/views/admin/adminUserManagement/employeeManagement/employeeInformation.vue'),
                meta: {
                  pageTitle: 'Employee Information',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    {
                      text: 'Employees',
                      to: '/employee-mgmt'
                    },
                    {
                      text: 'Employee Information',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },

              // Role Management
              {
                path: '/card/role-mgmt',
                name: 'role-mgmt',
                component: () => import('@/views/card/roleManagement/roleListing.vue'),
                meta: {
                  pageTitle: 'Employee Roles',
                  breadcrumb: [
                    {
                      text: 'Admin User',
                    },
                    {
                      text: 'Employee Roles',    
                      active: true,      
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },    
              },
              // edit Role
              {
                path: '/card/role-detail-mgmt/:id/:name',
                name: 'role-detail-mgmt',
                component: () => import('@/views/card/roleManagement/editUserRole.vue'),
                meta: {
                  pageTitle: 'Manager',
                  breadcrumb: [
                    {
                      text: 'Admin User',
                      to:'/card/role-mgmt'
                    },
                    {
                      text: 'Manager',    
                      active: true,      
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },    
              },
            )
          }
          else if(routeData.name=='University/Courses' || routeData.name=='Uni./Course Management')
          {
            routes.push(
              //Field & Course Mgmt
              {
                path: '/uni-corse-mgmt/create-field-course', 
                name: 'create-field-course',
                component: () => import('@/views/admin/uni_courseManagement/createFieldCourse.vue'),
                meta: {
                  pageTitle: 'Create Field & Course',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                      to: '/uni-course-mgmt/field-course-mgmt'
                    },
                    {
                      text: 'Create Field & Course',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/uni-course-mgmt/field-course-mgmt',
                name: 'field-course-mgmt',
                component: () => import('@/views/admin/uni_courseManagement/fieldCourseManagement.vue'),
                meta: {
                  pageTitle: 'Field & Course Mgmt',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                    },
                    {
                      text: 'Field & Course Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/uni-course-mgmt/field-course-info',
                name: 'field-course-info',
                component: () => import('@/views/admin/uni_courseManagement/fieldCourseInformation.vue'),
                meta: {
                  pageTitle: 'Field & Course Info',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                      to: '/uni-course-mgmt/field-course-mgmt'
                    },
                    {
                      text: 'Field & Course Mgmt',
                      to: '/uni-course-mgmt/field-course-mgmt'
                    },
                    {
                      text: 'Field & Course Info',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/uni-course-mgmt/edit-field-course',
                name: 'edit-field-course',
                component: () => import('@/views/admin/uni_courseManagement/editFieldCourse.vue'),
                meta: {
                  pageTitle: 'Field & Course Edit',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                      to: '/uni-course-mgmt/field-course-mgmt'
                    },
                    {
                      text: 'Field & Course Mgmt',
                      to: '/uni-course-mgmt/field-course-mgmt'
                    },
                    {
                      text: 'Field & Course Edit',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
  
  
              {
                path: '/uni-course-mgmt/university-mgmt', 
                name: 'university-mgmt',
                component: () => import('@/views/admin/uni_courseManagement/universityList.vue'),
                meta: {
                  pageTitle: 'University Mgmt',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                    },
                    {
                      text: 'University Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/uni-course-mgmt/college-mgmt', 
                name: 'college-mgmt',
                component: () => import('@/views/admin/uni_courseManagement/collegeList.vue'),
                meta: {
                  pageTitle: 'College Mgmt',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                    },
                    {
                      text: 'College Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/uni-course-mgmt/college-info', 
                name: 'college-info',
                component: () => import('@/views/admin/uni_courseManagement/collegeInformation.vue'),
                meta: {
                  pageTitle: 'College Info',
                  breadcrumb: [
                    {
                      text: 'University/Courses',
                      to: '/uni-course-mgmt/college-mgmt'
                    },
                    {
                      text: 'College Info',
                      active: true,
                    },      
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          }
          else if(routeData.name=='Documents')
          {
            routes.push(
              //Documents
              {
                path: '/doc-category-mgmt',
                name: 'doc-category-mgmt',
                component: () => import('@/views/admin/docCategoryManagement/categoryManagment.vue'),
                meta: {
                  pageTitle: 'Document Category',
                  breadcrumb: [        
                    {
                      text: 'Documents',
                      to: '/doc-category-mgmt'
                    },
                    {
                      text: 'Document Category',
                      active:true        
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            
              //Documents
              {
                path: '/doc-category-mgmt/category-detaile',
                name: 'category-detaile',
                component: () => import('@/views/admin/docCategoryManagement/categoryDetail.vue'),
                meta: {
                  pageTitle: 'Education',
                  breadcrumb: [
                    {
                      text: 'Documents',          
                    },
                    {
                      text: 'Document Category',
                      to: '/doc-category-mgmt',
                    },
                    {
                      text: 'Education',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },

              //Document List
              {
                path: '/doc-mgmt',
                name: 'doc-mgmt',
                component: () => import('@/views//ui/typography/documentManagment.vue'),
                meta: {
                  pageTitle: 'Document List',
                  breadcrumb: [        
                    {
                      text: 'Documents',
                      to: '/doc-mgmt'        
                    },
                    {
                      text: 'Document List',
                      active :true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              }
            )
          }
          else if(routeData.name=='Company Document')
          {
            routes.push(
              //Create document module [JYOTI]
              {
                path: '/doc-category-mgmt/create-doc-category',
                name: 'create-doc-category',
                component: () => import('@/views/admin/docCategoryManagement/categoryModuleComponet.vue'),
                meta: {
                  pageTitle: 'Create New Category',
                  breadcrumb: [
                    {
                      text: 'Documents',
                      to:'/doc-category-mgmt'
                    },
                    {
                      text: 'Create New Category',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              
              //Edit doc module
              {
                path: '/doc-category-mgmt/edit-doc-category/:id',
                name: 'edit-doc-category',
                component: () => import('@/views/admin/docCategoryManagement/editCategory.vue'),
                meta: {
                  pageTitle: 'Edit Doc Module',
                  breadcrumb: [
                    {
                      text: 'Document List',
                      to:'/doc-category-mgmt'
                    },
                    {
                      text: 'Edit Doc Module',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            
              //Company Document
              {
                path: '/company-document',
                name: 'company-document',
                component: () => import('@/views/ui/typography/companyDocument.vue'),
                meta: {
                  pageTitle: 'Company Document',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              }
            )
          } else if (routeData.name == 'Promotional Notification'){
            routes.push(
              {
                path: '/promotional',
                name: 'promotional-notification',
                component: () => import('@/views/admin/notificationManagment/PromotionalNotificationList.vue'),
                meta: {
                  pageTitle: 'Promotional Notification',
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                },
              },
              {
                path: '/promotional-send',
                name: 'promotional-notification-send',
                component: () => import('@/views/admin/notificationManagment/promotinalNotification.vue'),
                meta: {
                  pageTitle: 'Create Promotional Notification',
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                    {
                      text: 'Promotional Notification',
                      to:'/promotional'
                    },
                  ],
                },
              },
            )
          } else if (routeData.name == 'Announcement'){
            routes.push(
              {
                path: '/announcement',
                name: 'announcement',
                component: () => import('@/views/admin/announcementManagement/announcementList.vue'),
                meta: {
                  pageTitle: 'Announcement',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/announcement-create',
                name: 'announcement-create',
                component: () => import('@/views/admin/announcementManagement/createAnnouncement.vue'),
                meta: {
                  pageTitle: 'Create Announcement',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                    {
                      text: 'Announcement',
                      to:'/announcement'
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          } else if (routeData.name == 'Community'){
            routes.push(
              {
                path: '/community',
                name: 'community',
                component: () => import('@/views/admin/communityManagement/communityTab.vue'),
                meta: {
                  pageTitle: 'Community',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          } else if(routeData.name == 'Information Document') {
            routes.push(
              {
                path: '/information-document',
                name: 'information-document',
                component: () => import('@/views/admin/infoDocumentManagement/informationDocumentList.vue'),
                meta: {
                  pageTitle: 'Information Document',
                  breadcrumb: [],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/information-crerate',
                name: 'information-crerate',
                component: () => import('@/views/admin/infoDocumentManagement/informationDecument.vue'),
                meta: {
                  pageTitle: 'Create Information Document',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                    {
                      text: 'Information Document',
                      to:'/information-document'
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/information-update/:id',
                name: 'information-update',
                component: () => import('@/views/admin/infoDocumentManagement/informationUpdateDecument.vue'),
                meta: {
                  pageTitle: 'Update Information Document',
                  breadcrumb: [
                    // {
                    //   text: 'UI',
                    // },
                    // {
                    //   text: 'Student List',
                    //   active: true,
                    // },
                    {
                      text: 'Information Document',
                      to:'/information-document'
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          } else if(routeData.name == 'Notice Management') {
            routes.push({
              path: '/notice-mgnt',
              name: 'notice-mgnt',
              component: () => import('@/views/admin/noticeManagement/noticeLists.vue'),
              meta: {
                pageTitle: 'Notice Management',
                breadcrumb: [
                  // {
                  //   text: 'UI',
                  // },
                  // {
                  //   text: 'Student List',
                  //   active: true,
                  // },
                ],
                action: routeData.allow.toLowerCase(),
                resource: 'EAC',
              },
            },
            {
              path: '/notice-create',
              name: 'notice-create',
              component: () => import('@/views/admin/noticeManagement/createNotice.vue'),
              meta: {
                pageTitle: 'Create Notice',
                breadcrumb: [
                  // {
                  //   text: 'UI',
                  // },
                  // {
                  //   text: 'Student List',
                  //   active: true,
                  // },
                  {
                    text: 'Notice Management',
                    to:'/notice-mgnt'
                  },
                ],
                action: routeData.allow.toLowerCase(),
                resource: 'EAC',
              },
            },
            {
              path: '/notice-update/:id',
              name: 'notice-update',
              component: () => import('@/views/admin/noticeManagement/editNotice.vue'),
              meta: {
                pageTitle: 'Update Notice',
                breadcrumb: [
                  // {
                  //   text: 'UI',
                  // },
                  // {
                  //   text: 'Student List',
                  //   active: true,
                  // },
                  {
                    text: 'Notice Management',
                    to:'/notice-mgnt'
                  },
                ],
                action: routeData.allow.toLowerCase(),
                resource: 'EAC',
              },
            },
            )
          }
          else if(routeData.name == 'Application Note Management') {
            routes.push(
              {
                path: '/application-note-mgnt',
                name: 'application-note-mgnt',
                component: () => import('@/views/admin/applicationNoteManagement/noteLists.vue'),
                meta: {
                  pageTitle: 'Application Note Management',
                  breadcrumb: [],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/application-note-create',
                name: 'application-note-create',
                component: () => import('@/views/admin/applicationNoteManagement/createNote.vue'),
                meta: {
                  pageTitle: 'Create Application Note',
                  breadcrumb: [
                    {
                      text: 'Application Note Management',
                      to:'/application-note-mgnt'
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/application-note-update/:id',
                name: 'application-note-update',
                component: () => import('@/views/admin/applicationNoteManagement/editNote.vue'),
                meta: {
                  pageTitle: 'Update Application Note',
                  breadcrumb: [
                    {
                      text: 'Application Note Management',
                      to:'/application-note-mgnt'
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          }
          else if(routeData.name=='Education Fields Management')
          {
            routes.push(
              //Field & Course Mgmt
              {
                path: '/edu-detail-mgmt/degree-list-mgmt', 
                name: 'degree-list-mgmt',
                component: () => import('@/views/admin/educationManagement/educationList.vue'),
                meta: {
                  pageTitle: 'Degree List Mgmt',
                  breadcrumb: [
                    {
                      text: 'Education Fields Management',
                    },
                    {
                      text: 'Degree List Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/edu-detail-mgmt/university-list-mgmt', 
                name: 'university-list-mgmt',
                component: () => import('@/views/admin/educationManagement/universityList.vue'),
                meta: {
                  pageTitle: 'University List Mgmt',
                  breadcrumb: [
                    {
                      text: 'Education Fields Management',
                    },
                    {
                      text: 'University List Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/edu-detail-mgmt/school-college-list-mgmt', 
                name: 'school-college-list-mgmt',
                component: () => import('@/views/admin/educationManagement/schoolCollegeList.vue'),
                meta: {
                  pageTitle: 'School/College List Mgmt',
                  breadcrumb: [
                    {
                      text: 'Education Fields Management',
                    },
                    {
                      text: 'School/College List Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
              {
                path: '/edu-detail-mgmt/branch-stream-field-mgmt', 
                name: 'branch-stream-field-mgmt',
                component: () => import('@/views/admin/educationManagement/filedStreamList.vue'),
                meta: {
                  pageTitle: 'Branch/Stream/Field List Mgmt',
                  breadcrumb: [
                    {
                      text: 'Education Fields Management',
                    },
                    {
                      text: 'Branch/Stream/Field List Mgmt',
                      active: true,
                    },
                  ],
                  action: routeData.allow.toLowerCase(),
                  resource: 'EAC',
                },
              },
            )
          }
        })
      }
      return routes;
    }
    else
    {
      return routes;
    }
  }
  else
  {
    return routes;
  };
}
export default getRoutesData();